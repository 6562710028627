.ButtonHablemos {
    height: auto;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    /* Letra en negrita */
    font-family: "Montserrat";
    background-color: white;
    color: #433B24;
    opacity: 1; /* Ajusta este valor según tus necesidades */
    margin: 20px auto;
    padding: 4px 20px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;

}  