.ButtonReservarHeader {
    background-color: transparent;
    height: auto;
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    /* Letra en negrita */
    font-family: "Montserrat";
    color: white;
    background-color: #005e38;
    opacity: 1; /* Ajusta este valor según tus necesidades */
    margin: 20px auto;
    padding: 4px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;

}  