.card-experiencias {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.filaExperienciaTipos {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
}

.columna-tipo-experiencias {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    width: 100vw;
    height: auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; /* Agrega espacio entre los servicios */
}

.ContenedorImagen {
    position: relative;
    width: 80%;
    height: auto;
    overflow: hidden; /* Asegura que el contenido adicional esté oculto */
    border-radius: 8px;
}

.ContenedorImagen img {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    border-radius: 8px;
    display: block;
}



.NombreExperiencia {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-align: center;
}

/* Estilos adicionales para dispositivos móviles */
@media (max-width: 1100px) {
    .filaExperienciaTipos {
        flex-direction: column;
    }
    .columna-tipo-experiencias {
        width: 100%;
    }
    .ContenedorImagen {
        width: 90vw;
    }
}